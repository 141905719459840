<template>
  <div style="padding: 20px">
      <div class="handle-box" >
          <el-button type="primary" style="margin-right:10px" class="mr10" icon="Plus" @click="adddepartment">添加</el-button>
            <el-input placeholder="部门名称" style="width: 200px;margin-right:10px"  class="mr10" v-model="query.keyword">
            </el-input>
            <el-button type="primary" style="margin-right:10px" icon="Search" @click="handleSearch">搜索</el-button>
        </div>
         <el-table
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          :row-style="{height:10+'px'}"
          :cell-style="{padding:5+'px'}"
          header-cell-class-name="table-header"
    >
    <el-table-column       
      prop="id"
          label="ID"
      ></el-table-column>
      <el-table-column     
          prop="name"
          label="部门名称"
      ></el-table-column>
      <el-table-column  
          prop="createTime"
          label="创建时间"
      ></el-table-column>
      <el-table-column             
          label="状态"
          width="150"
      >
      <template #default="scope">
            <el-tooltip :content="scope.row.state=='开启'? '关闭后此将无法使用':'确定要开启吗'" placement="top">
              <el-switch
                  @click="setState(scope.row.id,scope.row.state)"
                  v-model="scope.row.state"
                  active-value="开启"
                  inactive-value="禁用"
              >
              </el-switch>
            </el-tooltip>
          </template></el-table-column>
      <el-table-column                     
          label="第一负责人"
          prop="directorName"
          width="150"
      >
      <template #default="scope">
        <!-- {{scope.row}} -->
            <el-tag>
              {{ scope.row.directorName!==null? scope.row.directorName:'暂无'}}
            </el-tag>
            <div>
                
            </div>
          </template>
    </el-table-column>
    <el-table-column   
         prop="viceDirectorName"
          label="第二负责人"
          width="150"
      >
      <template #default="scope">
            <el-tag>
              {{ scope.row.viceDirectorName!==null? scope.row.viceDirectorName:'暂无'}}
            </el-tag>
            <div>     
            </div>
          </template>
    </el-table-column>
    <el-table-column    
        prop="parentName"                 
          label="父级"
          width="150"
      >
      <template #default="scope">
            <el-tag>
              {{ scope.row.parentName!==null? scope.row.parentName:'暂无'}}
            </el-tag>
            <div>             
            </div>
          </template>
    </el-table-column>
    <el-table-column label="操作" width="200" align="center">
              <template #default="scope">
                <el-button
                  type="text"
                  icon="el-icon-edit"
                  @click="updateCampusItem(scope.row)">编辑
                </el-button>
              </template>
            </el-table-column>
    </el-table>
    <!--分页-->
    <div class="pagination">
      <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.page"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>
    </div>
   <!-- 添加 -->
    <el-dialog v-model="addframework">
        <div  custom-class="innerDialog" style="padding:10px 50px 0 0">
        <el-form>              
            <el-row>
          <el-col :span="8">
            <el-form-item label="校区名称" label-width="80px">
              <el-input v-model="updateForm.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
          <el-form-item label="类型" label-width="80px" >
            <el-select @change="choiceFlag()" placeholder="类型" v-model="updateForm.flag">        
                <el-option label="中招体育" :value="1"></el-option>
                <el-option label="少年" :value="2"></el-option>
                <el-option label="高考体育" :value="4"></el-option>                  
            </el-select>
          </el-form-item>
        </el-col>
          <el-col :span="8">
            <el-form-item label="第一负责人" label-width="80px">
                <el-select
                :disabled="updateForm.flag==null ? true:false"
                v-model="updateForm.directorId"
                filterable placeholder="请选择">
              <el-option
                  v-for="item in coachList"
                  :key="item.coachid"
                  :label="item.coachname"
                  :value="item.coachid"
              />
            </el-select>  
            </el-form-item>
          </el-col>
        </el-row>
          <el-row>
            <el-col :span="8">
            <el-form-item  label="第二负责人" label-width="80px">
                <el-select
                :disabled="updateForm.flag==null ? true:false"
                v-model="updateForm.viceDirectorId"
                filterable placeholder="请选择">
              <el-option
                  v-for="item in coachList"
                  :key="item.coachid"
                  :label="item.coachname"
                  :value="item.coachid"
              />
            </el-select>  
            </el-form-item>
          </el-col>
            <el-col :span="8">
            <el-form-item  label="状态" label-width="80px">
              <el-select  
              placeholder="请选择"  
              collapse-tags
              collapse-tags-tooltip
              v-model="updateForm.state"
              >
                <el-option key="1" label="开启" value="开启"></el-option>
                <el-option key="2" label="关闭" value="禁用"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
            <el-col :span="8" >
            <el-form-item label="父级" prop="fatherId" label-width="80px">
              <el-cascader
              v-model="updateForm.parentId"
                  :props="{
                   value: 'id',
                   label: 'name',
                   children: 'children',
                   checkStrictly: true}"
                  :options="treeList"/>
            </el-form-item>
        </el-col>
        </el-row>
        </el-form>
        </div>
        <template #footer>
          <span class="dialog-footer">
        <el-button @click="addframework = false">取消</el-button>
        <el-button type="primary" @click="addCampus()">确定</el-button>
      </span>
      </template>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog v-model="editframework">
        <div  custom-class="innerDialog" style="padding:10px 50px 0 0">
        <el-form :model="updateForm">        
            <el-row>
          <el-col :span="8">
            <el-form-item label="校区名称" prop="name" label-width="80px">
              <el-input v-model="updateForm.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
          <el-form-item label="类型" prop="flag" label-width="80px" >
            <el-select @change="choiceFlag()" placeholder="类型" v-model="updateForm.flag">        
                <el-option label="中招体育" :value="1"></el-option>
                <el-option label="少年" :value="2"></el-option>
                <el-option label="高考体育" :value="4"></el-option>             
            </el-select>
          </el-form-item>
        </el-col>
          <el-col :span="8">
            <el-form-item label="第一负责人" prop="directorId" label-width="80px">
                <el-select
                v-model="updateForm.directorName"
                filterable placeholder="请选择">
              <el-option
                  v-for="item in coachList"
                  :key="item.coachid"
                  :label="item.coachname"
                  :value="item.coachid"
              />
            </el-select>  
            </el-form-item>
          </el-col>
        </el-row>
          <el-row>
            <el-col :span="8">
            <el-form-item  label="第二负责人" prop="viceDirectorId" label-width="80px">
                <el-select
                v-model="updateForm.viceDirectorName"
                filterable placeholder="请选择">
              <el-option
                  v-for="item in coachList"
                  :key="item.coachid"
                  :label="item.coachname"
                  :value="item.coachid"
              />
            </el-select>  
            </el-form-item>
          </el-col>
            <el-col :span="8">
            <el-form-item  label="状态"  label-width="80px">
              <el-select  
              placeholder="请选择"  
              collapse-tags
              collapse-tags-tooltip
              v-model="updateForm.state"
              >
                <el-option key="1" label="开启" value="开启"></el-option>
                <el-option key="2" label="关闭" value="禁用"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
            <el-col :span="8" >
            <el-form-item label="父级" prop="parentId" label-width="80px">
              <el-cascader
              v-model="updateForm.parentId"
                  :props="{
                   value: 'id',
                   label: 'name',
                   children: 'children',
                   checkStrictly: true}"
                  :options="treeList"/>
            </el-form-item>
        </el-col>
        </el-row>
        </el-form>
        </div>
        <template #footer>
          <span class="dialog-footer">
        <el-button @click="editframework = false">取消</el-button>
        <el-button type="primary" @click="editCampus()">确定</el-button>
      </span>
      </template>
    </el-dialog>
</template>
<script>
import {page,getListTree,add,edit,updateState} from '@/api/framework'
import { number } from '@intlify/core-base'
import {listCoachAll} from '../../api/coach'
        export default{ 
            data(){ 
                return{ 
                    coachList:[],
                    updateForm:{
                        name:'',
                        flag:'',
                        directorId:'',
                        viceDirectorId:'',
                        state:'',
                        parentId:'',
                        directorName:'',
                        viceDirectorName:''
                    },
                    editframework:false,
                    addframework:false,
                    tableData:[],
                    treeList:[],
                    query:{ 
                        keyword:'',
                        pageIndex:1,
                        pageSize:10
                    },
                    pageTotal:'',
                    coacahName:''
                }
            },
            created(){ 
                this.getPage()
                this.getTree()
            },
    methods: {
      setState(id,state){ 
        console.log(id,state);
        updateState(id,state).then(res=>{ 
          if(res.code==200){ 
            this.$message.success('更新成功');
            this.getPage(this.query)
          }else{ 
            this.$message.error('更新失败！');
          }
        })
      },
      //搜索按钮
      handleSearch(){ 
        this.getPage()
      },
      //添加新部门
      adddepartment(){ 
        this.addframework = true
        this.updateForm = {
          name: '',
          flag: '',
          directorId: '',
          viceDirectorId: '',
          state: '',
          parentId: '',
          directorName: '',
          viceDirectorName: ''
        }
      },  
      //确认编辑编辑
        editCampus(){ 
            // console.log(this.updateForm); 
            if(!this.updateForm.flag){ 
                console.log(1);
            }else{ 
            this.updateForm.directorId = this.updateForm.directorName
            this.updateForm.viceDirectorId = this.updateForm.viceDirectorName
            } 
            if(typeof(this.updateForm.parentId)==number){ 
              console.log('a');
            }else{ 
              this.updateForm.parentId = this.updateForm.parentId[this.updateForm.parentId.length-1]
            }   
            
            edit(this.updateForm).then(res=>{ 
                if(res.code==200){ 
                   this.getPage(this.query)
                }else{ 
                  this.$message.error('操作失败')
                }
            }) 
            this.editframework = false
           
        },  
        //类型改变
        choiceFlag(){ 
                // console.log(this.updateForm.flag);
                listCoachAll(this.updateForm.flag).then(res=>{ 
                    if(res.code==200){ 
                        this.coachList = res.data
                    }else{ 
                        this.$message.error(`请求校区负责人失败！`);
                    }
                })
        },
        //添加
        addCampus(){ 
            this.updateForm.parentId = this.updateForm.parentId[this.updateForm.parentId.length-1]
            add(this.updateForm).then(res=>{ 
                if(res.code==200){ 
                  this.getPage(this.query)
                }else{ 
                  this.$message.error('操作失败')
                }
            })
            this.addframework = false
        },
        getTypeList(commodityType) {
            if (commodityType != null) {
                commodityType.forEach(items => {
                    if (items != null) {
                        if (items.children != null && items.children.length > 0) {
                            this.getTypeList(items.children);
                        } else {
                            items.children = undefined;
                        }
                    }
                });
                return commodityType;
            }
        },
                getTree(){ 
                    getListTree().then(res=>{ 
                        // console.log(res);
                        this.treeList = this.getTypeList(res.data)
                    })
                },
                //编辑按钮
                updateCampusItem(val){ 
                    this.editframework = true
                    this.updateForm = val
                    this.coachList = []
                    // console.log(this.updateForm);
                    // console.log(this.updateCampus);
                },
                //分页
                handlePageChange(val){ 
                    this.query.pageIndex = val
                    this.getPage();
                
                },
                getPage(){ 
                    page(this.query).then(res=>{ 
                        this.tableData = res.data.records
                        this.pageTotal = res.data.total
                    })
                },
              

            }
        } 
        
</script>
<style scoped>
    .content{ 
        height: 100%;
        width: 98%;
        background: white;
    }
    .handle-box{ 
      margin-bottom: 20px;
    }
</style>